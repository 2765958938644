import clsx from 'clsx';
import {useGTMDispatch} from '@elgorditosalsero/react-gtm-hook';
import * as rdd from 'react-device-detect';
import * as styles from './heroContact.module.css';

const HeroContact = () => {
    const sendDataToGTM = useGTMDispatch();

    const handleAnchorClick = () => {
        sendDataToGTM({
            event: 'mailClick',
            mailAddress: 'hello@digitalvision.cz',
            device: rdd.deviceType,
        });
    };

    return (
        <div className={styles.hero}>
            <div className={styles.mobileHeader}>
                <div className={clsx(styles.digitalWrapper, styles.animation)}>
                    <h1 className={clsx(styles.mainHeadingOne, styles.animation)}>
                        Digital
                    </h1>
                </div>
                <div className={clsx(styles.visionWrapper, styles.animation)}>
                    <h1 className={clsx(styles.mainHeadingTwo, styles.animation)}>
                        Vision
                    </h1>
                </div>
            </div>
            <div className={clsx(styles.digitalWrapper, styles.animation, styles.desktopHeader)}>
                <h1 className={clsx(styles.mainHeadingOne, styles.animation)}>
                    Digital Vision
                </h1>
            </div>
            <div
                data-aos="fade-up"
                data-aos-delay="600"
                data-aos-duration="1000"
                data-aos-easing="ease-out"
            >
                <div className={styles.textEmail}>
                    Napište nám na<br/>
                    <a
                        href="mailto:hello@digitalvision.cz"
                        className={styles.email}
                        onClick={handleAnchorClick}
                    >
                        hello@digitalvision.cz
                    </a>
                </div>
            </div>

        </div>
    );
};

export default HeroContact;
