// extracted by mini-css-extract-plugin
export var animation = "heroContact-module--animation--URV65";
export var desktopHeader = "heroContact-module--desktopHeader--9U+vF";
export var digitalWrapper = "heroContact-module--digitalWrapper--54kh2";
export var email = "heroContact-module--email--NJAf-";
export var goDown = "heroContact-module--goDown--aZXFJ";
export var heading = "heroContact-module--heading--TmPOv";
export var hero = "heroContact-module--hero--+rnpp";
export var icons = "heroContact-module--icons--iHC7t";
export var mainHeadingOne = "heroContact-module--mainHeadingOne--xlJWM";
export var mainHeadingTwo = "heroContact-module--mainHeadingTwo--JZHhh";
export var mobileHeader = "heroContact-module--mobileHeader--bTk0G";
export var textEmail = "heroContact-module--textEmail--jpX6S";
export var upFloat = "heroContact-module--upFloat--hnfzL";
export var visionWrapper = "heroContact-module--visionWrapper--N6KxN";