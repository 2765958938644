import {Helmet} from 'react-helmet';
import clsx from 'clsx';
import {useRef, useEffect} from 'react';
import {useTransitionState, useTriggerTransition} from 'gatsby-plugin-transition-link/hooks';
import {useGTMDispatch} from '@elgorditosalsero/react-gtm-hook';
import {Link} from 'gatsby';
import {ContactForm} from '../components';
import {Layout, Header} from '../components/layout';
import pageTypes from '../utils/enums/pageType';
import HeroContact from '../components/layout/hero/heroContact';
import {isExiting, isEntering} from '../utils/animations';
import scrollTo from '../utils/scrollTo';
import * as styles from './contact.module.css';

const ContactPage = () => {
    const sendDataToGTM = useGTMDispatch();
    const formRef = useRef();
    const {entry, transitionStatus} = useTransitionState();
    const exiting = isExiting(transitionStatus);
    const entering = isEntering(transitionStatus);
    const entryTransition = useTriggerTransition({
        to: '/contact',
        entry: {
            length: 0.5,
            state: {
                pageType: pageTypes.contact,
                nextPageType: pageTypes.contact,
            },
        },
    });

    if (entering && typeof window !== 'undefined' && window && entry.state.scrollTrigger && formRef && formRef.current) {
        setTimeout(() => {
            scrollTo(formRef);
        }, 500);
    }

    useEffect(() => {
        if (entry && Object.keys(entry.state).length > 0 && entry.state.isTransition && transitionStatus !== 'exited') {
            return;
        }

        entryTransition({});
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const anchorHref = 'https://www.mfgroup.cz/';
    const anchorText = 'MEDIA FACTORY GROUP';

    const handleAnchorClick = () => {
        sendDataToGTM({
            event: 'linkClick',
            linkText: anchorText,
            targetURL: anchorHref,
        });
    };

    const text = 'Jsme členem ';

    return (
        <Layout hideFooterButton={true}>
            <Helmet>
                <title>Contact | Digital Vision CZ</title>
                <meta
                    name="description"
                    content="Máte pro nás nabídku spolupráce, dotaz nebo jiný podnět? Nechte nám vzkaz."
                />
            </Helmet>
            <Header pageType={pageTypes.contact}>
                <HeroContact/>
            </Header>
            <div className={clsx(styles.root, exiting && styles.exiting)}>
                <div className={styles.contactsWrapper}>
                    <div className={styles.contacts}>
                        <div className={styles.contactGroup}>
                            <h5>
                                Adresa
                            </h5>
                            DIGITAL VISION CZECH REPUBLIC, a.s.<br/>
                            <br/>
                            Green Park Offices<br/>
                            Žerotínova 32<br/>
                            130 00 Praha 3 - Žižkov<br/>
                        </div>
                        <div className={styles.contactGroup}>
                            <h5>
                                Fakturace
                            </h5>
                            IČ: 03177238<br/>
                            DIČ: CZ03177238<br/>
                            Číslo účtu: 2110087011/2700<br/>
                            IBAN: CZ7327000000002110087011<br/>
                            SWIFT: BACXCZPP<br/>
                            Datová schránka: xh3kd7u<br/>
                            <br/>
                            Společnost zapsaná v obchodním rejstříku vedeném Městským soudem v Praze, oddíl B, vložka 19855.<br/>
                            <br/>
                            <Link to="/obchodni-podminky">Ochrana osobních údajů</Link><br/>
                            <br/>
                            {text}
                            <a
                                onClick={handleAnchorClick}
                                href={anchorHref}
                                target="_blank"
                                rel="noreferrer"
                            >
                                {anchorText}
                            </a>
                        </div>
                    </div>
                </div>
                <div id="contact" className={styles.contactFormWrapper}>
                    <div
                        data-contact-form
                        ref={formRef}
                        className={styles.contactForm}
                    >
                        <h5>Napište nám</h5>
                        <span>Máte pro nás nabídku spolupráce, dotaz nebo jiný podnět? Nechte nám vzkaz.</span>
                        <ContactForm isContact={true}/>
                    </div>
                </div>
            </div>
        </Layout>
    );
};

export default ContactPage;
